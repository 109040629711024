<div class="landing-container">
    <ng-container *ngIf="isShepherd">
        
        
        <div class="body-top">
            
            <div class="content-left">
                <div class="rollover-holder" *ngIf="showRolloverNotification">
                    <div class="rollover-container">
                        <app-notification (dismissNotification)="dismissNotification($event)" (followRoute)="followRoute()" *ngIf="currentApplicationWindow?.academicYear">
                            <div class="notification-hover">
                                <b>Want to be an RS next year?</b>&nbsp;
                                Resubmit your current application now to apply for the
                                &nbsp;{{formattedYear(currentApplicationWindow.academicYear.acyrCode, 1)}}-{{formattedYear(currentApplicationWindow.academicYear.acyrCode, 2)}}&nbsp;year.
                            </div>
                        </app-notification>
                        
                    </div>   
                </div>
                
                
                <h1 class="like-h1 welcome-message">{{welcomeMessage}}</h1>
                <div class="welcome-buttons">
                    <button *ngIf="isRS" class="large" [routerLink]="['/dashboard/rs']">Review Applicants</button>
                    <button *ngIf="isFutureRS || isRS" class="large" [routerLink]="['/dashboard/cgl-search']">Review Hirable CGLs</button>
                    <button class="button large ghost" [routerLink]="['/profile']">View Your Profile</button>
                </div>
            </div>
            <div class="body-top-image">
                <img [src]="welcomeImg" alt="">
            </div> 
        </div>
    </ng-container>

    <ng-container *ngIf="!isShepherd">
        <div class="body-top-ns">
            <div class="body-top-ns-image">
                <img [src]="welcomeImg" alt="">
            </div>
            <p class="like-h1 center-text">{{welcomeMessageNS}}</p>
        </div>
    </ng-container>
<hr>
    <div class="body-bottom">
        <h2  class="like-h3 position-header">
            {{cards?.length ? 'Open Positions' : 'No Open Positions'}}
        </h2>
        
        <div class="grid">
          
            <ng-container *ngFor="let card of cards">
                <ng-container *ngIf="$any(card).applicationId">
                    <ng-container [ngSwitch]="card.applicationType">

                        <ng-container *ngSwitchCase="'CGL'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: cglImg, headerText: 'Community Group Leader', applicationType: 'CGL', applied: true}"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'RS'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: rsImg, headerText: 'Resident Shepherd', applicationType: 'RS', applied: true}"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'CS'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: csImg, headerText: 'Commuter Shepherd', applicationType: 'CS', applied: true}"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'AS'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: asImg, headerText: 'Athletic Shepherd', applicationType: 'AS', applied: true}"></ng-container>
                        </ng-container>

                    </ng-container>
                </ng-container>

                <ng-container *ngIf="!$any(card).applicationId">
                    <ng-container [ngSwitch]="card.applicationType">
                        
                        <ng-container *ngSwitchCase="'CGL'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: cglImg, headerText: 'Community Group Leader', applicationType: 'CGL'}"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'RS'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: rsImg, headerText: 'Resident Shepherd', applicationType: 'RS'}"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'CS'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: csImg, headerText: 'Commuter Shepherd', applicationType: 'CS'}"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'AS'">
                            <ng-container *ngTemplateOutlet="CardRef; context: {imgType: asImg, headerText: 'Athletic Shepherd', applicationType: 'AS'}"></ng-container>
                        </ng-container>

                    </ng-container>
                </ng-container>

            </ng-container>

        </div>
    </div>
</div>

<ng-template #CardRef let-imgType="imgType" let-headerText="headerText" let-applicationType="applicationType" let-applied="applied">
    <div [id]="applicationType + '-card' + (applied ? '-applied' : '')" class="card">
        <div class="card-image">
            <img [src]="imgType" alt="">
        </div>
        <div class="text">
            <h3 class="like-h2 card-header">{{headerText}}</h3>
            <p class="body">{{welcomeMessageNS}}</p>
        </div>
        <button *ngIf="!applied" class="button padded" (click)="onApplyClicked(applicationType)" [attr.aria-label]="applyNowAriaLabel(headerText)" >Apply Now</button>
        <app-status-display *ngIf="applied" [showCheck]="true" [badgeOnly]="true">Application Submitted</app-status-display>
    </div>
</ng-template>
